<script lang="ts" setup>
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';

import _ from 'lodash';
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';

import { AccessValidationStatus, get } from '@azure/services/servicePrincipal';
import { getSubscriptions } from '@azure/services/subscriptions';
import { useAzureStore } from '@azure/state';

import PageLayout from '@console/PageLayout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CodePreview from '@shared/design/CodePreview.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';
import Tabs from '@shared/design/Tabs.vue';

const items = [
  { key: 'az_cli', label: 'Azure CLI' },
  { key: 'az_portal', label: 'Azure Portal' },
];
const activeKey = ref('az_cli');
const onTabChange = (key: string) => {
  activeKey.value = key;
};

const store = useAzureStore();
const billingScopeId = computed(() => store.getActiveBillingScopeId());
const servicePrincipal = ref<CreateServicePrincipalResponse | undefined>(undefined);
const backRoute = ref('');

const validationStatus = ref(AccessValidationStatus.Unknown);
const loading = ref(true);

const route = useRoute();

const tenantId = computed<string>(() => {
  // route params can be an array but we never actually use it like that for awsAccountId
  // so we are coercing to a string here.
  if (typeof route.params.tenantId === 'string') {
    return route.params.tenantId;
  }
  return '';
});

onMounted(async () => {
  loading.value = true;
  if (billingScopeId.value) {
    const subscriptions = await getSubscriptions(billingScopeId.value);
    if (_.some(subscriptions, { tenant_id: tenantId.value, status: 'AccessError', billing_status: 'Active' })) {
      validationStatus.value = AccessValidationStatus.AccessDenied;
    } else {
      validationStatus.value = AccessValidationStatus.Validated;
    }
    const activeBillingScope = await store.getBillingScopeById(billingScopeId.value);
    if (activeBillingScope) {
      backRoute.value = activeBillingScope.status === 'Active' ? 'azure_tenants' : 'azure_onboarding';
      const servicePrincipalId = activeBillingScope.service_principal_id;
      if (servicePrincipalId) {
        servicePrincipal.value = await get(servicePrincipalId);
      }
    }
  }
  loading.value = false;
});

const tenantOnboardingCodeSnippet = (tenantId: string) => {
  return `az login --allow-no-subscriptions --tenant "${servicePrincipal.value?.tenant_id}"

APP_ID = "${servicePrincipal.value?.application_id}"
az ad app update --id $APP_ID --sign-in-audience "AzureADMultipleOrgs"

az login --allow-no-subscriptions --tenant "${tenantId}"

SPN_OBJECT_ID=$(az ad sp create --id $APP_ID --query 'id' --output tsv)

az account management-group list

ROOT_MANAGEMENT_GROUP=$(az account management-group list --query "[?contains(displayName, 'Tenant Root Group')] | [0].name" --output tsv)

az role assignment create --assignee-object-id $SPN_OBJECT_ID --assignee-principal-type ServicePrincipal --role "Billing Reader" --scope "/providers/Microsoft.Management/managementGroups/$ROOT_MANAGEMENT_GROUP"
`;
};
</script>

<template>
  <PageLayout :loading="loading">
    <div>
      <div class="row pb-3">
        <div class="col">
          <router-link :to="{ name: backRoute }">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to Azure Tenants List
          </router-link>
        </div>
      </div>
      <PageHeader wrap-utility>
        <h1>Azure Tenant Configuration</h1>
      </PageHeader>
      <BoxMessage v-if="validationStatus === AccessValidationStatus.Validated" type="success" class="pt-4 pb-2">
        <strong>This Azure Tenant is fully configured!</strong>
        <p class="mb-0">
          We have confirmed that the ProsperOps role is configured correctly and no further action is necessary.
        </p>
      </BoxMessage>
      <BoxMessage v-else-if="validationStatus === AccessValidationStatus.AccessDenied" type="error" class="pt-4 pb-2">
        <strong>Configuration Error</strong>
        <p class="mb-0">
          We were unable to validate the ProsperOps role configuration for this Azure Tenant. Please review the
          instructions below and ensure the role is configured correctly.
        </p>
      </BoxMessage>
      <div class="row pt-2">
        <div class="col">
          <div class="row">
            <div class="col">
              <Tabs :items="items" :active-key="activeKey" @changed="onTabChange" />
            </div>
          </div>
          <div v-if="activeKey === 'az_portal'" class="row">
            <div class="col">
              <Panel>
                <div>
                  <ol>
                    <li>
                      Open
                      <a
                        href="https://portal.azure.com/#view/Microsoft_Azure_Resources/ManagementGroupBrowseBlade/~/MGBrowse_overview"
                        target="_blank"
                        >Management groups</a
                      >
                      in the Azure portal
                    </li>
                    <li>Select the root management group (default name: <b>Tenant Root Group</b>)</li>
                    <li>
                      Select <b>Access control (IAM)</b> on the left blade, then click
                      <b>Add > Add role assignment</b>
                    </li>
                    <li>Select the <b>Billing Reader</b> role, then click <b>Next</b></li>
                    <li>Add the <b>ProsperOps</b> service principal as a member</li>
                    <li>Click <b>Review + assign</b></li>
                  </ol>
                </div>
              </Panel>
            </div>
          </div>
          <div v-if="activeKey === 'az_cli'" class="row">
            <div class="col-lg-3 pt-2">
              <Panel>
                <div>
                  <p>Copy, paste, and execute the Azure CLI command(s)</p>
                </div>
              </Panel>
            </div>
            <div class="col-lg-9 pt-2">
              <CodePreview
                :code-as-text="tenantOnboardingCodeSnippet(tenantId)"
                header-text="ProsperOps Limited Savings Analysis Access"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
</style>
