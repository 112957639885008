import type { AwsAccountStatus } from '@console/services/api.models';

import _ from 'lodash';

import { azure } from '@shared/services/api';


export type AzureTenantStatus =
  | 'Provisioning'
  | 'ProvisioningError'
  | 'AccessError'
  | 'Active'

export type SubscriptionResponse = {
  azure_subscription_id: string;
  azure_billing_scope_id: string;
  vendor_id: string;
  name: string;
  display_name: string;
  sku_id: string;
  sku_description: string;
  billing_status: string;
  status: AzureTenantStatus;
  created_date: string;
  updated_date?: string;
  tenant_id?: string;
}

export type Tenant = {
  tenant_id: string;
  subscriptions: SubscriptionResponse[];
  validated?: boolean;
  status?: AzureTenantStatus;
}

export async function getSubscriptions(billingScopeId: string) {
  const res = await azure.get<SubscriptionResponse[]>(`/v1/billing_scopes/${billingScopeId}/subscriptions`);
  return res.data;
}

export async function getSubscriptionsGroupedByTenant(billingScopeId: string): Promise<Tenant[]> {
  const subscriptions = await getSubscriptions(billingScopeId);;
  const tenants = _.chain(subscriptions)
    .filter(sub => sub.billing_status !== 'Deleted')
    .groupBy('tenant_id')
    .map((value, key) => ({
      tenant_id: key,
      validated: !_.some(value, { status: 'AccessError' }),
      open: true,
      subscriptions: value,
    }))
    .orderBy(tenant => tenant.validated, 'asc')
    .value();
  return tenants;
}
